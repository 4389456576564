import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ComponentProps } from 'react';

type Props = ComponentProps<typeof Link>;

/**
 * next/linkのwrapper
 * - pathnameがhrefと一致した場合、aタグではなくspanタグをrenderする
 */
export function SusnetLink(props: Props) {
  const pathname = usePathname();
  if (props.href === pathname) {
    return <SusnetMockLink {...props} />;
  }

  return <Link {...props} />;
}

type SusnetMockLink = Pick<Props, 'className' | 'style' | 'onClick' | 'children'>;

export function SusnetMockLink(props: SusnetMockLink) {
  return (
    <span className={props.className} style={props.style} onClick={props.onClick}>
      {props.children}
    </span>
  );
}
