import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useState, useEffect, useRef } from 'react';
import { atom, useRecoilState } from 'recoil';

import { getNavigationRailList } from './Config';

// NOTE: `cachedIsRouteToAdminPage`がないとLinkで遷移した時にチラつく
const cachedIsRouteToAdminPage = atom({
  key: 'cachedIsRouteToAdminPage',
  default: false,
});

export function NavigationRail({ isOrg }: { isOrg: boolean }) {
  const currentPath = usePathname();
  const { isMouseOver, targetElRef } = useMouseOver();

  const [isRouteToAdminPage, setIsRouteToAdminPage] = useRecoilState(cachedIsRouteToAdminPage);
  useEffect(() => {
    if (isRouteToAdminPage && isMouseOver) {
      setIsRouteToAdminPage(false);
    }
  }, [isMouseOver]);

  const isOpenNavBar = isRouteToAdminPage || isMouseOver;

  return (
    <div ref={targetElRef}>
      {/* NOTE: 80pxはHeaderの高さを示す */}
      <div
        className={clsx(
          'fixed inset-0 w-fit overflow-y-auto bg-white pt-[80px] transition-[box-shadow]',
          isOpenNavBar ? 'shadow-elevation-level2' : 'shadow-elevation-level1',
        )}
      >
        {currentPath && (
          <div className='flex flex-col gap-2 overflow-y-auto pt-6'>
            {getNavigationRailList({ isOrg }).map((it, index) => {
              const isActive = it.isActive(currentPath);
              return (
                <Link
                  key={index}
                  href={it.path}
                  className={clsx(
                    'flex h-[56px] items-center overflow-x-hidden p-4 text-label-large transition-colors',
                    isActive
                      ? 'cursor-default font-bold text-blue-80'
                      : 'text-disabled hover:text-gray-70',
                  )}
                  onClick={() => setIsRouteToAdminPage(true)}
                >
                  {it.Icon && <it.Icon className='h-6 w-6' />}
                  <div
                    className={clsx(
                      'whitespace-nowrap transition-[width,opacity] duration-300',
                      isOpenNavBar ? 'w-40' : 'w-0 opacity-0',
                    )}
                  >
                    <div className='ml-2'>{it.name}</div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

function useMouseOver() {
  const targetElRef = useRef<HTMLDivElement>(null);
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
    const handleMouseOver = () => setIsMouseOver(true);
    const handleMouseOut = () => setIsMouseOver(false);
    if (targetElRef.current) {
      targetElRef.current.addEventListener('mouseover', handleMouseOver);
      targetElRef.current.addEventListener('mouseout', handleMouseOut);
    }
    return () => {
      if (targetElRef.current) {
        targetElRef.current.removeEventListener('mouseover', handleMouseOver);
        targetElRef.current.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, [targetElRef.current]);

  return { targetElRef, isMouseOver };
}
