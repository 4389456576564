import { PropsWithChildren, useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';

const MountedState = atom({
  key: 'MountedState',
  default: false,
});

// refs: https://stackoverflow.com/questions/58293542/next-js-warning-expected-server-html-to-contain-a-matching-a-in-div-how-to
/** ssrの時はchildrenを描写せず、webの時のみchildrenを描写する util component */
export function OnlyWeb({ children }: PropsWithChildren) {
  const [isMounted, setIsMounted] = useRecoilState(MountedState);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }
  return <>{children}</>;
}
