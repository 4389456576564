import { NextSeo, NextSeoProps } from 'next-seo';
import { PropsWithChildren } from 'react';

import config from '~/config';

const OG_IMAGE = 'https://susnet.jp/og.jpg';

/** @see https://github.com/garmeeh/next-seo#nextseo-options */
const DEFAULT_SEO = {
  defaultTitle: 'サステナNet',
  titleTemplate: '%s | サステナNet',
  description:
    'サステナNetは企業からソーシャルセクターへの寄付先をコンペ形式にて募集し、社員参画型の社会貢献活動の広がりと波及効果を最大限にするICHI COMMONSのサービスです。',
  openGraph: {
    type: 'site',
    url: 'https://susnet.jp',
    images: [{ url: OG_IMAGE }],
  },
  themeColor: '#dedede',
  twitter: {
    cardType: 'summary_large_image',
  },
  noindex: config.env !== 'production',
} as const satisfies NextSeoProps;

export type SimpleNextSeoProps = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  noindex?: boolean;
};

type WithNextSeoProps = PropsWithChildren & SimpleNextSeoProps;

/**
 * titleのみを指定するシンプルなSEO
 * descriptionはtitleから自動生成される
 * また、ogp imageにはichiの正方形のロゴが使用される
 */
export function WithNextSeo({
  title,
  description,
  image,
  url,
  noindex,
  children,
}: WithNextSeoProps) {
  const merged: NextSeoProps = {
    ...DEFAULT_SEO,
    title,
    description: description
      ? omitForOgpDescription(description)
      : title
      ? `サステナNetの${title}のページです。`
      : DEFAULT_SEO.description,
    openGraph: {
      ...DEFAULT_SEO.openGraph,
      url: url || DEFAULT_SEO.openGraph.url,
      images: [{ url: image || OG_IMAGE }],
    },
    noindex: noindex || DEFAULT_SEO.noindex,
  };

  return (
    <>
      <NextSeo {...merged} />
      {children}
    </>
  );
}

function omitForOgpDescription(text: string): string {
  const textRemoveLine = text.replaceAll(/\n+/g, ' ');
  let description = textRemoveLine.slice(0, 70);
  if (textRemoveLine.length > 70) {
    description += '...';
  }
  return description;
}
