import { Button } from '~/components/Button/Button';

import Logo from '@public/images/logo.svg';

type Link = {
  label: string;
  link: string;
};

const menuLinks: Link[] = [
  {
    label: '活動報告',
    link: '/activities',
  },
  {
    label: 'チャレマ',
    link: '/challenges',
  },
  {
    label: 'プロジェクト',
    link: '/projects',
  },
  {
    label: '企業',
    link: '/corps',
  },
  {
    label: 'NPO',
    link: '/orgs',
  },
  {
    label: '社会課題',
    link: '/social-issues/general-social-issues',
  },
  {
    label: '会員登録・ログイン',
    link: '/login',
  },
];

const contactLinks = [
  {
    label: 'ご利用を検討中の方はこちら',
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSdHmnLZMI1WeMG-EUEcpahATCiOv_yg6gN0iI_mBaPFvcOD8A/viewform',
  },
  {
    label: 'SPOの方はこちら',
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSdHmnLZMI1WeMG-EUEcpahATCiOv_yg6gN0iI_mBaPFvcOD8A/viewform',
  },
  {
    label: '企業の方はこちら',
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSdHmnLZMI1WeMG-EUEcpahATCiOv_yg6gN0iI_mBaPFvcOD8A/viewform',
  },
];

const siteLinks = [
  {
    label: '運営会社',
    link: 'https://corp.ichicommons.com',
  },
  {
    label: 'サービス',
    link: 'https://ichicommons.com',
  },
  {
    label: 'サステナPress',
    link: 'https://press.ichicommons.com',
  },
  {
    label: 'お問い合わせ',
    link: 'https://docs.google.com/forms/d/e/1FAIpQLSdHmnLZMI1WeMG-EUEcpahATCiOv_yg6gN0iI_mBaPFvcOD8A/viewform',
  },
];

const termLinks = [
  {
    label: '利用規約',
    link: '/terms',
  },
  {
    label: 'プライバシーポリシー',
    link: '/privacy',
  },
];

export const Footer = () => {
  return (
    <div className='flex flex-col items-center gap-8 bg-secondary px-5 py-10 lg:px-0 lg:py-16'>
      <div className='flex w-full max-w-[960px] flex-col items-start gap-2 lg:flex-row lg:items-center lg:gap-8'>
        <Logo className={'w-[141px] lg:w-[224px]'} />
        <p className='text-body-medium'>誰もが社会課題解決の主役になれる世界</p>
      </div>
      <div className='flex w-full flex-row gap-6 lg:w-auto'>
        <div className='flex flex-col gap-8 lg:min-w-[468px]'>
          <div className='flex flex-col gap-4'>
            <p className='text-label-strong-x-large'>メニュー</p>
            <div className='flex flex-col items-start pr-7 lg:pr-0'>
              {menuLinks.map((link, index) => (
                <Button
                  key={index}
                  variant='link'
                  link={{ href: link.link }}
                  className='!p-2 font-normal'
                >
                  {link.label}
                </Button>
              ))}
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <p className='text-label-strong-x-large'>お問い合わせ</p>
            <div className='flex flex-col items-start pr-7 lg:pr-0'>
              {contactLinks.map((link, index) => (
                <Button
                  key={index}
                  variant='link'
                  link={{ href: link.link }}
                  className='!p-2 font-normal'
                >
                  {link.label}
                </Button>
              ))}
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-8 lg:min-w-[468px]'>
          <div className='flex flex-col gap-4'>
            <p className='text-label-strong-x-large'>関連サイト</p>
            <div className='flex flex-col items-start'>
              {siteLinks.map((link, index) => (
                <Button
                  key={index}
                  variant='link'
                  link={{ href: link.link }}
                  className='!p-2 font-normal'
                >
                  {link.label}
                </Button>
              ))}
            </div>
          </div>
          <div className='flex flex-col gap-4'>
            <p className='text-label-strong-x-large'>各種規定</p>
            <div className='flex flex-col items-start'>
              {termLinks.map((link, index) => (
                <Button
                  key={index}
                  variant='link'
                  link={{ href: link.link }}
                  className='!p-2 font-normal'
                >
                  {link.label}
                </Button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <p className='text-body-medium text-regular'>©ICHI Commons Co., Ltd.</p>
    </div>
  );
};
