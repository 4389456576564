import { SusnetImage } from '../_wrapper/SusnetImage';

type Props = {
  /** size: 6種類の大きさから選択 */
  size?: 128 | 64 | 48 | 40 | 32 | 24;
  /** customSize: sizeにない値を設定したい場合。値は `h-1 w-1` のように設定する。 */
  customSize?: string;
  /** src: avatarに挿入したい画像のURL. defaultは `/images/icon.png` */
  src?: string;
  /** alt: avatarの代替テキスト. defaultは `avatar` */
  alt?: string;
};

const sizeStyle = {
  24: 'h-6 w-6',
  32: 'h-8 w-8',
  40: 'h-10 w-10',
  48: 'h-12 w-12',
  64: 'h-16 w-16',
  128: 'h-32 w-32',
};

export function Avatar({ size = 48, customSize, src, alt = 'avatar' }: Props) {
  return (
    <div className={`relative rounded-corner-pill ${sizeStyle[size]} ${customSize || ''}`}>
      <SusnetImage
        src={src ?? '/images/icon.png'}
        alt={alt}
        fill
        sizes='100vw'
        className='rounded-corner-pill object-cover'
      />
    </div>
  );
}
