type NavigationBarListProps = {
  name: string;
  path: string;
};

export const NavigationBarList: NavigationBarListProps[] = [
  { name: '活動報告', path: '/activities' },
  { name: 'チャレマ', path: '/challenges' },
  { name: 'プロジェクト', path: '/projects' },
  { name: '企業', path: '/corps' },
  { name: 'NPO', path: '/orgs' },
  { name: '社会課題', path: '/social-issues/general-social-issues' },
];
