import { captureException } from '@sentry/nextjs';
import Image, { ImageProps } from 'next/image';
import { useState } from 'react';

const ERROR_IMAGE_URL_CACHE = new Set<string>();

type Props = Omit<ImageProps, 'src' | 'alt'> & {
  src?: ImageProps['src'];
  alt?: string;
};

export function SusnetImage(props: Props) {
  const imageUrl = getImageUrl(props.src);
  const [isError, setIsError] = useState(() => ERROR_IMAGE_URL_CACHE.has(imageUrl));

  const imageProps: ImageProps = {
    ...props,
    src: props.src || '',
    alt: props.alt || 'image',
    // NOTE: next/imageの最適化処理でエラーになった場合、isError=true にして最適化させない
    unoptimized: props.unoptimized || isError,
    onError: (e) => {
      props.onError?.(e);
      setIsError(true);
      ERROR_IMAGE_URL_CACHE.add(imageUrl);
    },
  };

  // NOTE: data-srcを指定する理由はnext/imageのurlがわかりづらいので、image urlをわかりやすくするため
  // eslint-disable-next-line
  return <Image data-src={imageUrl} {...imageProps} />;
}

function getImageUrl(src?: ImageProps['src']): string {
  if (!src) return '';
  if (typeof src === 'string') return src;

  const staticImageData = 'default' in src ? src.default : src;
  return staticImageData.src;
}
