import Router from 'next/router';

import { CurrentSessionT } from '~/_legacy/api/Session';

import { Avatar } from '~/components/Avatar/Avatar';
import { Button } from '~/components/Button/Button';
import { SusnetLink } from '~/components/_wrapper/SusnetLink';
import { useToggle } from '~/hooks/useToggle';
import { authToken } from '~/utils';

import Logo from '@public/images/logo.svg';
import IconXWhite from '@public/new-icons/filled-icon/icon_x-white.svg';

/** ログイン専用のページをここに指定し、ログアウト時はトップページに戻るようにしている */
const LOGIN_PAGE_PATH = ['/corp-admin', '/rooms'];

type Props = {
  currentUser: CurrentSessionT | undefined | null;
};

export function NavigationBarAdmin({ currentUser }: Props) {
  const [isShowAccountMenu, toggleIsShowAccountMenu] = useToggle();

  // if (currentUser === undefined || currentUser === null) {
  //   return (location.href = '/');
  // }

  /** NOTE: ログアウト時の挙動 */
  function onClickLogOut() {
    authToken.delete();
    if (LOGIN_PAGE_PATH.some((it) => Router.asPath.includes(it))) {
      location.href = '/';
    } else {
      location.reload();
    }
  }

  const ModalAccountMenu = () => {
    return (
      <div className='mt-3 flex w-[calc(100vw-24px)] max-w-[356px] flex-col rounded-corner-l bg-white px-xl__sp shadow-elevation-level2 lg:mt-0 lg:max-w-[240px] lg:p-l'>
        {/* ModalBody */}
        <div className='flex flex-col items-center gap-l__sp px-0 py-xl__sp lg:gap-0 lg:py-0'>
          <div className='flex w-full flex-col items-center gap-s__sp pb-l__sp pt-xs__sp lg:gap-s lg:pb-l lg:pt-xs'>
            <Avatar size={64} src={currentUser?.image() || ''} alt={currentUser?.name} />
            <div className='flex flex-col items-center'>
              <div className='text-body-large font-bold text-primary'>{currentUser?.name}</div>
            </div>
          </div>

          <Button variant='link' onClick={onClickLogOut}>
            ログアウト
          </Button>
        </div>
      </div>
    );
  };

  const NavigationBarRight = () => {
    return (
      <div
        className='relative flex cursor-pointer items-center justify-end'
        onClick={toggleIsShowAccountMenu}
      >
        <Avatar size={32} src={currentUser?.image() || ''} alt={currentUser?.name} />

        {isShowAccountMenu && (
          <div className='absolute right-[-20px] top-16 block'>
            <ModalAccountMenu />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className='fixed z-10 flex h-[80px] w-full items-center justify-between bg-white pl-10 pr-8 shadow-elevation-level2'>
      <SusnetLink href={'/'} className='w-[111px] lg:w-[140px]'>
        <Logo />
      </SusnetLink>

      <NavigationBarRight />
    </div>
  );
}
